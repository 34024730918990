/* Mixins */
.wrapper {
  margin: 20px 20px 0;
  overflow: scroll;
}
.ui-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #fff;
  position: relative;
  padding: 20px;
  overflow-y: scroll;
}
.left-panel {
  width: 370px;
  padding: 0 15px;
  border: 1px #f1e8e8 solid;
}
.left-panel .components-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.left-panel .components-list .component-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.left-panel .components-list .component-list-item .components-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
}
.left-panel .components-list .component-list-item .components-title .title-icon {
  font-size: 30px;
  padding: 10px;
}
.left-panel .components-list .component-list-item .components-draggable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.left-panel .components-list .component-list-item .components-draggable .components-item {
  width: calc(50% - 5px);
  margin: 5px 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f6f7ff;
}
.left-panel .components-list .component-list-item .components-draggable .components-item:hover {
  cursor: move;
  border: 1px dashed #787be8;
  border-radius: 6px;
}
.center-panel {
  width: calc(100% - 400px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.center-panel .middle-button {
  position: absolute;
  left: 50%;
  top: -10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.center-panel .middle-button .ant-btn {
  width: 100px;
}
.center-panel .drawing-board {
  background: #ebedf0;
  border: 1px solid #cdcdcd;
  border-radius: 20px;
  height: 772px;
  width: 375px;
  padding: 20px 10px;
  -webkit-box-shadow: 0 0 4px #333;
          box-shadow: 0 0 4px #333;
  overflow-y: scroll;
}
.center-panel .drawing-board .drawing-item-wrapper {
  position: relative;
}
.center-panel .drawing-board .drawing-item-wrapper .warning-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  color: red;
}
.center-panel .drawing-board .drawing-item-wrapper .seperator {
  width: 355px;
  height: 10px;
}
.center-panel .drawing-board .drawing-item-wrapper .seperator:hover {
  border: 0.5px dashed #787be8;
  border-radius: 6px;
  background: #999999;
  cursor: move;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item {
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: #eae9e9 solid 0.7px;
  border-width: 0 0 0.7px 0;
  width: 355px;
  padding-bottom: 1rpx;
  position: relative;
  min-height: 45px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .required-icon {
  color: #f57758;
  width: 10px;
  line-height: 45px;
  margin-left: 7.5px;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .required-icon-textarea {
  color: #f57758;
  width: 10px;
  line-height: 37.5px;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-left: 7.5px;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .form-flex-column-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .title {
  width: 125px;
  line-height: 35px;
  color: #333333;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .form-input-textarea {
  height: 75px;
  text-align: left;
  width: 330px;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .form-input-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .form-input-picture .form-upload-img-selector {
  height: 69px;
  width: 69px;
  border: 1px rgba(144, 147, 153, 0.5) dashed;
  color: rgba(0, 0, 0, 0.65);
  margin: 0 0 10px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .form-input-picture .form-upload-img-selector .upload-icon {
  height: 22.5px;
  margin-top: 10px;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .form-input-picture .form-upload-img-selector .upload-text {
  color: #abafb3;
  font-size: 10px !important;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .place-holder {
  width: 195px;
  text-align: right;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item .right-icon {
  position: absolute;
  right: 5px;
  color: #999999;
}
.center-panel .drawing-board .drawing-item-wrapper .drawing-item:hover {
  cursor: move;
  border: 0.5px dashed #787be8;
  border-radius: 6px;
}
.center-panel .empty-info {
  position: absolute;
  top: 46%;
  color: #ccb1ea;
  letter-spacing: 4px;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 18px;
}
.right-panel {
  width: 400px;
  border: 1px #f1e8e8 solid;
}
.right-panel .match {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px;
}
.right-panel .del-btn .ant-btn {
  width: 300px;
}
.selected {
  background: #80c6e9 !important;
  color: #fff !important;
}
.selected {
  background: #80c6e9;
}
.selected .title {
  color: #fff !important;
}
.selected .form-upload-img-selector {
  border: 1px #fff dashed !important;
}
.selected .form-upload-img-selector .anticon-upload {
  color: #fff;
}
.selected .form-upload-img-selector span {
  color: #fff !important;
}
.disabled {
  color: #999999;
}
.disabled:hover {
  cursor: no-drop;
}